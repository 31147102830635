
import { ref, defineComponent, onMounted, onBeforeRouteEnter } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Notice from './notice.vue';
import Exercise from './exercise.vue';
import Product from './product.vue';
import NoticeCell from './noticeCell.vue';
export default defineComponent({
  name: "index",
  components:{
    Notice,
    Exercise,
    Product,
    NoticeCell
  },
  setup(props, content) {
    const store = useStore();
    function openEdu(){
      window.open('https://edu.hnsyjzx.cn/data/web/')
    }
    return {
      openEdu
    };
  },
});
