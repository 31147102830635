<template>
    <div>
        <Notice></Notice>
        <!-- <div class="img">
          <el-image fit="cover" src="http://images1.wenming.cn/web_wenming/images/sy_index_top20190319-1.jpg"></el-image>
        </div> -->
        <NoticeCell></NoticeCell>
        <Exercise></Exercise>
        <Product></Product>
    </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted, onBeforeRouteEnter } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Notice from './notice.vue';
import Exercise from './exercise.vue';
import Product from './product.vue';
import NoticeCell from './noticeCell.vue';
export default defineComponent({
  name: "index",
  components:{
    Notice,
    Exercise,
    Product,
    NoticeCell
  },
  setup(props, content) {
    const store = useStore();
    function openEdu(){
      window.open('https://edu.hnsyjzx.cn/data/web/')
    }
    return {
      openEdu
    };
  },
});
</script>
<style lang="less" scoped>
.img{
  width: @mainWidth;
  max-width: 100%;
  text-align: center;
  margin: 30px auto;
  /deep/ .el-image{
     width: 100%;
     cursor: pointer;
  }
}
</style>