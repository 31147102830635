
import { ref, defineComponent, onMounted, onBeforeRouteEnter } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import excelData from "@assets/ts/getExcelData";
export default defineComponent({
  name: "exercise",
  setup(props, content) {
    const store = useStore();
    let exerciseList = ref([]);
    async function getExcelData() {
      let list = [
        {
          src: `${process.env.VUE_APP_FILE_HOST}/file/exercise/citystation.jpeg`,
          title: "郑州市着力打造<br/>室内检测与商混管理<br/>大数据“智慧监管”平台 ",
          content:
            "河南省郑州市近日举办“质筑根基智创未来”检测流程标准化推进会，正式启动工程质量检测“智慧监管”信息化平台，在全市76家工程质量检测机构推行事中事后“互联网+监管”模式，推动行业转型升级，以“智慧监管”信息化平台启动为契机，郑州工程质量检测行业将不断拓展检测领域，提升检测标准化水平，帮助企业转型发展，全面提升工程质量水平。",
        },
        {
          src: `${process.env.VUE_APP_FILE_HOST}/file/exercise/zhuti_test.png`,
          title: "2023年河南省工程质量检测机构<br/>主体结构检测能力比工作",
          content: `2023年 11月，开展了河南省工程质量检测机构主体结构检测能力比工作并对比对结果于2023 年11月底进行了公示，全省共有 369 家取得主体结构检测资质的检测机构，全部参加了能力比对，最终比对结果为优秀的企业 320 家，不满意的企业 49 家，最终满意(含优秀)率为 86.7%。`,
        },
        {
          src: `${process.env.VUE_APP_FILE_HOST}/file/exercise/neng_busin.png`,
          title: "河南省预拌混凝土企业<br/>原材料试验能力比对",
          content:
            "为了掌握我省预拌混凝土企业的质保体系运转情况，提升企业的技术能力，确保混凝土生产质量，河南省建设工程质量监督总站于 2020 年 9 月至2021 年 1 月在全省范围内组织开展了预拌混凝土企业原材料试验能力比对工作。",
        },
      ];
      exerciseList.value = list;
    }
    getExcelData();
    return {
      exerciseList,
    };
  },
});
