<template>
  <div class="noticeCellPageRoot" ref="noticeCellPageRootRef">
      <div class="scrollList" ref="scollListRef">
        <div v-for="(item,index) in list" :key="index" class="imgItem">
          <img :src="item" alt="">
        </div>
      </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted, watch } from "vue";
import { useRoute,useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "noticeCell",
  setup(props, content) {
    const store = useStore();
    const route = useRoute()
    const scollListRef = ref(null)
    const noticeCellPageRootRef = ref(null)
    const clientWidth = store.state.clientWidth;
    let intelScoll
    
    
    let scollI = 0
    const list = [
      require('@assets/img/NoticeCellImg/banner2.jpg'),
      require('@assets/img/NoticeCellImg/banner3.jpg'),
      require('@assets/img/NoticeCellImg/banner6.png'),
      require('@assets/img/NoticeCellImg/banner7.png'),
      require('@assets/img/NoticeCellImg/banner2.jpg'),
      require('@assets/img/NoticeCellImg/banner3.jpg'),
    ]
    function changeItem(){
      if(route.path != '/index'){
        clearInterval(intelScoll)
        return
      }
      scollI++
      const itemWidth = 608 /**单个图片转动宽度 */
      if(clientWidth >= 600){
        scollListRef.value.style.left = `${-(scollI*itemWidth)}px`
      } else {
        scollListRef.value.style.left = `${-(scollI*100)}vw`
      }
      if(scollI == list.length - 2){
        setTimeout(() => {
          scollListRef.value.style.transition = 'none'
          scollListRef.value.style.left = '0px'
          setTimeout(() => {
            scollListRef.value.style.transition = 'all 1s'
          }, 100);
          scollI = 0
        }, 1000);
      }
    }

    function startScoll(){
      if(route.path != '/index'){
        clearInterval(intelScoll)
      } else {
        intelScoll = setInterval(()=>{
          changeItem()
        },3000)
      }
    }

    onMounted(() => {
      startScoll()
    })
    return {
      list,
      scollListRef,
      noticeCellPageRootRef
    };
  },
});
</script>
<style lang="less" scoped>
.noticeCellPageRoot {
  width: @mainWidth;
  max-width: 100%;
  height: 90px;
  margin: 30px auto;
  overflow: hidden;
  position: relative;
  .scrollList{
    display: flex;
    position: absolute;
    left: 0px;
    transition: all 1s;
  }
  .imgItem{
    min-width: (@mainWidth - 16px)/2;
    padding-right: 16px;
    box-sizing: content-box;
    img{
      width: 100%;
    }
  }
}

  @media (max-width: 600px) {
  .noticeCellPageRoot{
    height:((53 / 375) * 100)vw;
    .imgItem{
      width: 100vw;
      min-width: 0;
      padding-right: 0;
    }
  }
}
</style>