<template>
  <div class="product">
    <h1 style="margin-bottom:40px;color:black">成果展示</h1>
    <div class="productContent">
      <div class="productWindow" @mouseenter="stopScoll" @mouseleave="startScoll">
        <div class="productlistContent" :style="{left:styleLeft+'px'}" ref="productlistContentRef">
          <div class="productListItem" v-for="(item,index) in exerciseList" :key="index">
            <el-image class="bannerImg" fit="contain" :src="item.src" :preview-src-list="[item.src]">
              <template #error>
                <div class="err-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
              <template #placeholder>
                <div class="err-slot">
                  加载中...
                </div>
              </template>
            </el-image>
            <div class="cover">
              <i class="el-icon-zoom-in"></i>
            </div>
            <div class="productTitle">{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted, onBeforeRouteEnter } from "vue";
import { useRoute,useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "product",
  setup(props, content) {
    const store = useStore();
    const route = useRoute()
    let exerciseList = ref([]);
    let productlistContentRef = ref(null);
    let styleLeft = ref("");
    let list = [];
    let intelScoll

    function changeToItem() {
      if(route.path != '/index')
      return
      let left = styleLeft.value-1
      const halfW = 240*exerciseList.value.length / 2
      if(left <= -halfW){
        left = 0
      }
      styleLeft.value = left
    }
    
    

    function stopScoll(){
      clearInterval(intelScoll)
    }

    function startScoll(){
      if(store.state.clientWidth <= 600 || list.length >=4){
        intelScoll = setInterval(() => {
          changeToItem();
        }, 30);
      }
    }

    async function getExcelData() {
      list = store.state.product
      if (store.state.clientWidth <= 600 || list.length >=4) {
        exerciseList.value = [...list, ...list];
        startScoll()
      } else {
        exerciseList.value = [...list];
      }
    }

    getExcelData();
    return {
      exerciseList,
      styleLeft,
      productlistContentRef,
      stopScoll,
      startScoll
    };
  },
});
</script>
<style lang="less" scoped>
@itemProductWidth: 284px;
@itemProductHeight: 220px;
@itemProductPadding: 10px;
@imgHeight:165px;
.product {
  width: @mainWidth;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  color: white;
  background-size: cover;
  background-position: center right;
  height: 377px;
  overflow: hidden;
  margin-top: 50px;
  .productContent {
    padding: 0 30px;
    position: relative;
    .productWindow {
      height: @itemProductHeight;
      overflow: hidden;
      position: relative;
      .productlistContent {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        width: max-content;
        margin: 0 auto;
        .productListItem {
          width: @itemProductWidth;
          min-height: @itemProductHeight;
          height: @itemProductHeight;
          background: white;
          margin: 0 10px;
          border-radius: 3px;
          background: #fbfbfb;
          background-size: 100% 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          &:hover{
            .productTitle{
              background: @orangeColor;
              color: white;
            }
            .cover{
              background: #00000099;
              i{
                display: inline;
              }
            }
          }
          .bannerImg {
            background: #bbbbbb21;
            color:  @orangeColor;
            flex: 1;
            width: 100%;
            /deep/ .el-icon-picture-outline{
              font-size: 40px;
            }
            .err-slot{
              text-align: center;
              color: @orangeColor;
            }
          }
          .productTitle {
            color: black;
            font-size: 17px;
            height: 55px;
            line-height: 55px;
            background: white;
          }
          .cover{
            height: @imgHeight;
            line-height: @imgHeight;
            width: 100%;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            i{
              font-size: 45px;
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>