
import { ref, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { study_getContent } from "@axios/index";
export default defineComponent({
  name: "notice",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    // const year = moment().format("YYYY");
    const year = 2024
    const bannerRef = ref(null);
    let bannerList = [
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/banner/关于河南省预拌混凝土企业试验实操视频观看的通知.png`,
        url: "https://mp.weixin.qq.com/s/JRTba44wG4k0Jp0tSwfOgA",
        title: "关于河南省预拌混凝土企业试验实操视频观看的通知",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/banner/《河南省预拌混凝土质量管理标准》宣贯会在商丘市成功举办.jpg`,
        url: "http://www.hnjsgcjs.cn/#/news/detail?id=51",
        title: "《河南省预拌混凝土质量管理标准》宣贯会在商丘市成功举办",
      },
      {
        src: `${process.env.VUE_APP_FILE_HOST}/file/banner/扎实推进技能河南建设 奏响高质量发展强音.jpeg`,
        url: "http://www.hnjsgcjs.cn/#/news/detail?id=49",
        title: "扎实推进技能河南建设 奏响高质量发展强音",
      },
    ];
    let studyList = ref([
      { month: 1, contentList: [] },
      { month: 2, contentList: [] },
      { month: 3, contentList: [] },
      { month: 4, contentList: ["预拌混凝土质量管理标准宣贯-信阳站","预拌混凝土质量管理标准宣贯-开封站"] },
      { month: 5, contentList: ["预拌混凝土质量管理标准宣贯-洛阳站","预拌混凝土质量管理标准宣贯-许昌站",'河南省建设工程检测员（建筑材料及构配件）','河南省建设工程检测员（市政工程材料）'] },
      { month: 6, contentList: ["河南省建设工程检测员（主体结构及装饰装修）", "河南省预拌混凝土企业试验员（混凝土）培训"] },
      { month: 7, contentList: [] },
      { month: 8, contentList: [] },
      { month: 9, contentList: [] },
      { month: 10, contentList: [] },
      { month: 11, contentList: [] },
      { month: 12, contentList: [] },
    ]);
    let newList = ref([]);
    let systemList = [
      {
        title: "河南省<br/>预拌混凝土<br/>信息数据平台",
        contentList: [
          // {
          //   label: "检测用户端",
          //   url: "https://hnsyjzx.cn/testin/#/loginIndex",
          // },
          { label: "企业端", url: "https://hnsyjzx.cn/busin/#/loginIndex" },
          { label: "管理端", url: "https://hnsyjzx.cn/admin/#/loginIndex" },
        ],
      },
      {
        title: "河南省<br/>预拌混凝土<br/>生产企业<br/>质量保证体系<br/>动态评价",
        contentList: [
          { label: "管理单位", url: "https://dongtaipingjia.com/admin/index.html" },
          { label: "预拌混凝土企业", url: "https://dongtaipingjia.com/busin/index.html" },
        ],
      },
      {
        title: "郑州市<br/>建设工程质量<br/>检测智慧平台",
        contentList: [
          { label: "检测企业端", url: "https://hnsyjzx.cn/testin/#/login" },
          { label: "商混企业端", url: "https://hnsyjzx.cn/busin/#/login" },
          { label: "管理端", url: "https://hnsyjzx.cn/admin/#/login" },
        ],
      },
      // {
      //   title: "河南省豫建中心<br/>“云课程”",
      //   img:require('@assets/img/Code/study_code.jpg'),
      //   imgLabel:'打开微信“扫一扫”，扫描二维码开始学习。',
      //   contentList: [
      //   ],
      // },
      {
        title: "培训记录查询",
        img:require('@assets/img/Code/study_search.png'),
        imgLabel:'打开微信“扫一扫”，扫描二维码开始学习。',
        contentList: [
        ],
      },
      {
        title: "郑州市室内环境<br/>检测管理系统",
        contentList: [
          { label: "企业端", url: "https://hnyj.hnsyjzx.cn" },
          { label: "管理端", url: "https://jczzhj.hnsyjzx.cn/pc/index.html" },
        ],
      },
      // {
      //   title: "河南省预拌混凝土<br/>生产企业试验<br/>能力比对系统",
      //   contentList: [
      //     { label: "企业端", url: "https://neng.hnsybsh.com/company" },
      //     { label: "管理端", url: "https://neng.hnsybsh.com" },
      //   ],
      // },
      // {
      //   title: "河南省建设工程质量<br/>检测机构<br/>能力比对系统",
      //   contentList: [
      //     { label: "企业端", url: "https://jzbd.hnsyjzx.cn/phone" },
      //     { label: "管理端", url: "https://jzbd.hnsyjzx.cn" },
      //   ],
      // },
      
      {
        title: "奖项查询系统",
        contentList: [
          { label: "查询系统", url: "https://henan.hnsyjzx.cn/admin/#/search" },
          { label: "平台", url: "https://professional.hnsyjzx.cn/index.html#/professor/login" },
        ],
      },
      {
        title: "答辩系统",
        contentList: [
          // { label: "专家端", url: "https://professional.hnsyjzx.cn/index.html#/professor/login" },
          { label: "答辩端", url: "https://professional.hnsyjzx.cn/index.html#/user/login" },
        ],
      },
      {
        title: "河南省豫建中心<br/>报名系统",
        contentList: [
          {
            label: "新进人员报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/new.html",
          },
          {
            label: "继续教育报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/continue.html",
          },
          {
            label: "技术提升班报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/up.html",
          },
          {
            label: "检测人员报名",
            url: "https://yjzx.hnsyjzx.cn/subInfo/test.html",
          },
          { label: "管理端", url: "https://yjzx.hnsyjzx.cn/memberAdmin" },
        ],
      },
      // {
      //   title: "答辩系统答辩端",
      //   contentList: [
      //     { label: "答辩端", url: "https://professional.hnsyjzx.cn/index.html#/user/login" },
      //   ],
      // },
    ];
    let indicatorIndex = ref(0);

    /**轮播转动事件 */
    function changeBanner(i) {
      indicatorIndex.value = i;
    }

    /**手动跳转轮播 */
    function toBannerImg(i) {
      bannerRef.value.setActiveItem(i);
    }

    /**跳转公众号 */
    function openWX() {
      window.open(
        "https://mp.weixin.qq.com/s/13fHevyEehhveapyc2pC7Q",
        "_blank"
      );
    }

    /**跳转系统入口 */
    function openSystem(url) {
      window.open(url, "_blank");
    }

    function toPage(item) {
      if(item.url){
        window.open(item.url, "_blank");
      }
    }

    function routerToNewsDetail(item) {
      if (item.href) {
        window.open(item.href, "_blank");
      } else {
        router.push({ path: `/news/detail`, query: { id: item.id } });
      }
    }

    function routerToNewsList() {
      router.push({ path: `/news`, query: { page: 1, key: "" } });
    }

    async function getNews() {
      const onlineNews = await study_getContent({});
      const wxNews = store.state.wxNews;
      const news = [...onlineNews, ...wxNews];
      newList.value = news
        .sort((a, b) => new Date(b["created_at"]).valueOf() - new Date(a["created_at"]).valueOf())
        .slice(0, 9)
        .map(item => {
          return {
            ...item,
            create_at: `[${moment(new Date(item.created_at)).format(
              "YYYY年MM月DD日"
            )}]`,
            create_at_min: moment(new Date(item.created_at)).format("YY/MM/DD"),
          };
        });
    }
    getNews();
    return {
      bannerList,
      newList,
      studyList,
      systemList,
      year,
      openSystem,
      openWX,
      routerToNewsDetail,
      routerToNewsList,
      router,
      changeBanner,
      indicatorIndex,
      toBannerImg,
      bannerRef,
      toPage,
    };
  },
});
